/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


/* .mat-button-base {
  margin: 8px 8px 8px 0!important;
} */


dt{
  color: gray;
  margin-top: 10px;
}

dd
{
  margin-left: 0px;
}

.spacer {
  flex: 1 1 auto;
}

[hidden]
{
  display:none!important
}



.scrollable
{
  overflow: scroll;
}

@media print {

  .mat-drawer-container , .mat-drawer-content {
    overflow: visible !important;
  }

  /* // Hide website controls when printing */
  .hide-print, .navbar, .sidenav.details, app-header, app-footer {
    display: none !important;
  }

  .print_content
  {
    visibility: visible;
    display: block;
  }
}
